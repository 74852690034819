import weddingPhoto from "../assets/images/weddingPhoto.jpg";
import privacyAR from "../assets/images/features/security-ar.jpg";
import privacyIT from "../assets/images/features/security-it.jpg";
import privacyFR from "../assets/images/features/security-fr.jpg";
import privacyES from "../assets/images/features/security-es.jpg";
import privacyEN from "../assets/images/features/security-en.jpg";
import profilesEN from "../assets/images/features/profiles-EN.jpg";
import profilesFR from "../assets/images/features/profiles-FR.jpg";
import profilesES from "../assets/images/features/profiles-ES.jpg";
import profilesAR from "../assets/images/features/profiles-AR.jpg";
import profilesIT from "../assets/images/features/profiles-IT.jpg";
import membershipEN from "../assets/images/features/membership-en.jpg";
import membershipFR from "../assets/images/features/membership-fr.jpg";
import membershipES from "../assets/images/features/membership-es.jpg";
import membershipAR from "../assets/images/features/membership-ar.jpg";
import membershipIT from "../assets/images/features/membership-it.jpg";
import activityEN from "../assets/images/features/activityEN.jpg";
import activityFR from "../assets/images/features/activityFR.jpg";
import activityES from "../assets/images/features/activityES.jpg";
import activityAR from "../assets/images/features/activityAR.jpg";
import activityIT from "../assets/images/features/activityIT.jpg";
import filtersEN from "../assets/images/features/filters-EN.jpg";
import filtersFR from "../assets/images/features/filters-FR.jpg";
import filtersES from "../assets/images/features/filters-ES.jpg";
import filtersAR from "../assets/images/features/filters-AR.jpg";
import filtersIT from "../assets/images/features/filters-IT.jpg";
import compatibilityEN from "../assets/images/features/compatibility-EN.jpg";
import compatibilityFR from "../assets/images/features/compatibility-FR.jpg";
import compatibilityES from "../assets/images/features/compatibility-ES.jpg";
import compatibilityAR from "../assets/images/features/compatibility-AR.jpg";
import compatibilityIT from "../assets/images/features/compatibility-IT.jpg";
import dynamicCardsEN from "../assets/images/features/dynamicCards-EN.jpg";
import dynamicCardsFR from "../assets/images/features/dynamicCards-FR.jpg";
import dynamicCardsES from "../assets/images/features/dynamicCards-ES.jpg";
import dynamicCardsAR from "../assets/images/features/dynamicCards-AR.jpg";
import dynamicCardsIT from "../assets/images/features/dynamicCards-IT.jpg";
import dynamicCardEN from "../assets/images/features/dynamicCard-EN.jpg";
import dynamicCardFR from "../assets/images/features/dynamicCard-FR.jpg";
import dynamicCardES from "../assets/images/features/dynamicCard-ES.jpg";
import dynamicCardAR from "../assets/images/features/dynamicCard-AR.jpg";
import dynamicCardIT from "../assets/images/features/dynamicCard-IT.jpg";

export const hostAndBackendPort = "https://www.mabrook.co/api/";
//export const hostAndBackendPort = "http://localhost:4000/api/";
export const mabrookEmail = "contact@mabrook.co";

export const features = [
  {
    title: {
      en: "Create Your Profile",
      fr: "Créer votre Profil",
      es: "Crear Perfil",
      ar: "إنشاء ملف شخصي",
      it: "Creare Profilo",
    },
    description: {
      en: "Build your profile and connect with fellow Moroccans worldwide who share the goal of finding marriage partners.",
      fr: "Créez votre profil et connectez-vous avec des Marocains du monde entier qui partagent le même objectif de trouver des partenaires de mariage.",
      es: "Crea tu perfil y conecta con otros marroquíes de todo el mundo que comparten el objetivo de encontrar parejas para el matrimonio.",
      ar: "قم ببناء ملفك الشخصي وتواصل مع زملائك المغاربة في جميع أنحاء العالم الذين يشاركون نفس الهدف في العثور على شركاء للزواج.",
      it: "Crea il tuo profilo e connettiti con altri marocchini in tutto il mondo che condividono l'obiettivo di trovare partner per il matrimonio.",
    },
    images: [
      {
        en: profilesEN,
        fr: profilesFR,
        es: profilesES,
        ar: profilesAR,
        it: profilesIT,
      },
    ],
  },
  {
    title: {
      en: "Privacy Settings",
      fr: "Paramètres de Confidentialité",
      es: "Configuración de Privacidad",
      ar: "إعدادات الخصوصية",
      it: "Impostazioni Privacy",
    },
    description: {
      en: "Customize your privacy settings to control the visibility of your photos and information. Choose who can see your profile, hide from specific users, and block unwanted contacts.",
      fr: "Personnalisez vos paramètres de confidentialité pour contrôler la visibilité de vos photos et informations. Choisissez qui peut voir votre profil, cachez-vous de certains utilisateurs et bloquez les contacts indésirables.",
      es: "Personalice la configuración de privacidad para controlar la visibilidad de sus fotos e información. Elija quién puede ver su perfil, ocúltese de usuarios específicos y bloquee contactos no deseados.",
      ar: "قم بتخصيص إعدادات الخصوصية للتحكم في رؤية صورك ومعلوماتك. اختر من يمكنه رؤية ملفك الشخصي ، واختر الإخفاء من بعض المستخدمين ، وقم بحظر الاتصالات غير المرغوب فيها.",
      it: "Personalizza le impostazioni della privacy per controllare la visibilità delle tue foto e informazioni. Scegli chi può vedere il tuo profilo, nasconditi da utenti specifici e blocca i contatti indesiderati.",
    },
    images: [
      {
        en: privacyEN,
        fr: privacyFR,
        es: privacyES,
        ar: privacyAR,
        it: privacyIT,
      },
    ],
  },
  {
    title: {
      en: "Filtering Options",
      fr: "Options de Filtrage",
      es: "Opciones de Filtrado",
      ar: "خيارات التصفية",
      it: "Opzioni di Filtraggio",
    },
    description: {
      en: "Refine your search with a range of filters, including basic and advanced criteria, to find matches tailored to your preferences.",
      fr: "Affinez votre recherche avec une gamme de filtres, y compris des critères de base et avancés, pour trouver des correspondances adaptées à vos préférences.",
      es: "Refine su búsqueda con una variedad de filtros, incluidos criterios básicos y avanzados, para encontrar coincidencias adaptadas a sus preferencias.",
      ar: "قم بتنقيح بحثك باستخدام مجموعة من المرشحات ، بما في ذلك المعايير الأساسية والمتقدمة ، للعثور على تطابقات مصممة وفقًا لتفضيلاتك.",
      it: "Affina la tua ricerca con una serie di filtri, inclusi criteri di base e avanzati, per trovare corrispondenze su misura per le tue preferenze.",
    },
    images: [
      {
        en: filtersEN,
        fr: filtersFR,
        es: filtersES,
        ar: filtersAR,
        it: filtersIT,
      },
    ],
  },
  {
    title: {
      en: "Compatibility Matching",
      fr: "Correspondance de Compatibilité",
      es: "Coincidencia de Compatibilidad",
      ar: "تطابق التوافق",
      it: "Corrispondenza di Compatibilità",
    },
    description: {
      en: "Discover users whose answers to a compatibility questionnaire align with yours. Review compatibility reports to gauge potential matches.",
      fr: "Découvrez des utilisateurs dont les réponses à un questionnaire de compatibilité sont alignées sur les vôtres. Consultez les rapports de compatibilité pour évaluer les correspondances potentielles.",
      es: "Descubre usuarios cuyas respuestas a un cuestionario de compatibilidad se alinean con las tuyas. Revisa los informes de compatibilidad para evaluar posibles coincidencias.",
      ar: "اكتشف المستخدمين الذين تتطابق إجاباتهم على استبيان التوافق مع إجاباتك. استعرض تقارير التوافق لقياس التطابقات المحتملة.",
      it: "Scopri utenti le cui risposte a un questionario di compatibilità si allineano con le tue. Consulta i rapporti di compatibilità per valutare le possibili corrispondenze.",
    },
    images: [
      {
        en: compatibilityEN,
        fr: compatibilityFR,
        es: compatibilityES,
        ar: compatibilityAR,
        it: compatibilityIT,
      },
    ],
  },
  {
    title: {
      en: "Dynamic Cards",
      fr: "Cartes Dynamiques",
      es: "Tarjetas Dinámicas",
      ar: "بطاقات ديناميكية",
      it: "Carte Dinamiche",
    },
    description: {
      en: "Share aspects of your lifestyle, beliefs, personality, values, and background by completing dynamic profile cards.",
      fr: "Partagez des aspects de votre mode de vie, de vos croyances, de votre personnalité, de vos valeurs et de votre parcours en remplissant des cartes de profil dynamiques.",
      es: "Comparta aspectos de su estilo de vida, creencias, personalidad, valores y antecedentes completando tarjetas de perfil dinámicas.",
      ar: "شارك جوانب من نمط حياتك ومعتقداتك وشخصيتك وقيمك وخلفيتك من خلال إكمال بطاقات الملف الشخصي الديناميكية.",
      it: "Condividi aspetti del tuo stile di vita, delle tue convinzioni, della tua personalità, dei tuoi valori e del tuo background completando carte di profilo dinamiche.",
    },
    images: [
      {
        en: dynamicCardsEN,
        fr: dynamicCardsFR,
        es: dynamicCardsES,
        ar: dynamicCardsAR,
        it: dynamicCardsIT,
      },
      {
        en: dynamicCardEN,
        fr: dynamicCardFR,
        es: dynamicCardES,
        ar: dynamicCardAR,
        it: dynamicCardIT,
      },
    ],
  },
  {
    title: {
      en: "Activity Tracking",
      fr: "Suivi des Activités",
      es: "Seguimiento de Actividad",
      ar: "تتبع النشاط",
      it: "Monitoraggio Attività",
    },
    description: {
      en: "Monitor your interactions, including likes, mutual matches, dislikes, and blocked users, to stay informed and organized.",
      fr: "Suivez vos interactions, y compris les likes, les correspondances mutuelles, les dislikes et les utilisateurs bloqués, pour rester informé et organisé.",
      es: "Monitorea tus interacciones, incluidos los me gusta, las coincidencias mutuas, los no me gusta y los usuarios bloqueados, para mantenerte informado y organizado.",
      ar: "راقب تفاعلاتك ، بما في ذلك الإعجابات ، والتطابقات المتبادلة ، والعدم إعجاب ، والمستخدمين المحظورين ، للبقاء مطلعًا ومنظمًا.",
      it: "Monitora le tue interazioni, incluse le preferenze, le corrispondenze reciproche, le non preferenze e gli utenti bloccati, per rimanere informato e organizzato.",
    },
    images: [
      {
        en: activityEN,
        fr: activityFR,
        es: activityES,
        ar: activityAR,
        it: activityIT,
      },
    ],
  },
];
