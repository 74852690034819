import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../styles/PrivacyPolicy.css";
import Sidebar from "./Sidebar";

const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <div className="privacy-policy-container">
        <h1 className="privacy-policy-heading">Privacy Policy</h1>
        <p className="privacy-policy-description">
          <b>Mabrook Inc.</b> ("<b>Mabrook</b>," "we," or "us") respects the
          privacy of its users ("you") and is committed to protecting your
          privacy and personal information. This Privacy Policy explains how we
          collect, use, share and protect your personal information when you use
          our mobile application and services. By accessing or using our
          website, application, or other online services, you agree to the terms
          of this Privacy Policy.
        </p>
        <p className="privacy-policy-description">
          If you have any questions about our privacy practices, please refer to
          the end of this Privacy Policy for information on how to contact us.
          Please read this Privacy Policy in conjunction with our Terms of
          Service.
        </p>

        <h2 className="section-heading">Information Collection</h2>
        <p className="section-content">
          <b>Personal Information: </b>We may collect Personal Information,
          including Sensitive Data, and other information. "Personal
          Information" means individually identifiable information that would
          allow us to determine the actual identity of, and contact, a specific
          living person. Sensitive Data includes information that you optionally
          provide, such as photographs, video, profile details, lifestyle
          information, ethnic origin, nationality, religion. By providing
          Sensitive Data to us, you consent to its collection, use, and
          disclosure as permitted by applicable privacy laws.
        </p>
        <p className="section-content">
          <b>Information You Provide: </b>When you register as a user with
          MABROOK, you may sign in using your Apple login, email address, gmail
          account or phone number. If you sign in using Apple or Gmail, you
          authorize us to access certain Apple or Gmail account information,
          such as your public profile, email address, gender, birthday. We may
          also collect and store any personal information you provide while
          using our Services or in some other manner. This may include
          identifying information such as your name, address, email address,
          telephone number, and, if you transact business with us, financial
          information. Additionally, you may provide us with photos, a personal
          description, and information about your gender.
        </p>
        <p className="section-content">
          <b>Fair Collection of Personal Data: </b>
          <ul>
            <li>
              <b>Data Related to Your Identity: </b>You are required to provide
              personal data when registering, including a mobile phone number
              for text alerts or and a step within our profile verification.
              Your email address and phone number are not visible or available
              to the public.
            </li>
            <li>
              <b>Location Data: </b>Instead of collecting geolocation data, we
              ask users to provide their country and city (location) during the
              registration process.
            </li>
            <li>
              <b>Transaction Data: </b>We do not handle payment transactions
              directly. Payment information is solely managed by Stripe.
            </li>
            <li>
              <b>Selfie: </b>We use facial image analysis to prevent fraudulent
              signups. Your selfie is collected during the signup process and
              used for liveness checks.
            </li>
            <li>
              <b>Information Collected by Third Parties for Advertising: </b>We
              may allow third parties to display advertisements on our Service
              and use tracking technologies to collect information about users
              who view or interact with their ads.
            </li>
            <li>
              <b>Contact for Other Dating Apps/Companies: </b>We may contact
              users to inform them about other dating apps/companies related to
              MABROOK that we believe they might find interesting or relevant.
              However, users have the option to opt out of receiving such
              communications at any time.
            </li>
          </ul>
        </p>
        <h2 className="section-heading">
          Use of technologies to collect information
        </h2>
        <p className="section-content">
          We employ diverse technologies to gather data from your device and
          track your interactions on our platform.
          <p>
            <b>Automated Information Collection: </b>Upon your visit to our
            platform, we automatically gather data from your browser or device.
            This may encompass your IP address, device ID, browser type and
            language, operating system details, access times, geographical
            location if our app is in use, and the website that directed you to
            ours.
          </p>
          <p>
            <b>Employment of Cookies and Similar Technologies:</b> During your
            visit, we might allocate one or more cookies or similar technologies
            to your device to enhance accessibility and personalize your
            experience. Utilizing cookies enables us to automatically capture
            details about your interactions on our platform, including visited
            pages, timestamps, and clicked links. Additionally, if we engage in
            advertising, either independently or through third parties, certain
            data collected on our platform may be utilized to display our
            advertisements on other websites or applications.
          </p>
        </p>

        <h2 className="section-heading">Cookie Policy </h2>
        <p className="section-content">
          This Cookie Policy explains how Mabrook Inc. ("Mabrook," "we," or
          "us") uses cookies and similar tracking technologies on our website
          and mobile application (collectively referred to as the "Platform").
          By using the Platform, you consent to the use of cookies in accordance
          with this policy.
          <p>
            <b>What Are Cookies and Similar Technologies? </b>Cookies are small
            text files that are stored on your device (such as your computer or
            mobile device) when you visit a website or use an app. They are
            widely used to make websites and apps work more efficiently and to
            provide information to the owners of the site or app. In addition to
            cookies, we may also use other tracking technologies such as web
            beacons, pixels, and tags to collect and track information about
            your interactions with the Platform.
          </p>
          <p>
            <b>Types of Cookies We Use: </b>We use the following types of
            cookies and similar technologies on our Platform:
            <ul>
              <li>
                <b>Essential Cookies: </b>These cookies are necessary for the
                operation of the Platform. They enable you to navigate the
                Platform and use its features, such as accessing secure areas of
                the site. Without these cookies, certain features of the
                Platform may not function properly.
              </li>
              <li>
                <b>Analytics Cookies: </b>These cookies allow us to collect
                information about how you use the Platform, such as which pages
                you visit and if you experience any errors. This information
                helps us analyze and improve the performance and usability of
                the Platform. We may use analytics cookies provided by
                third-party service providers such as Google Analytics.
              </li>
              <li>
                <b>Functionality Cookies: </b>These cookies enable the Platform
                to remember choices you make (such as your username, language,
                or region) and provide enhanced, more personalized features. For
                example, we may use functionality cookies to remember your
                preferences for language or currency settings.
              </li>
              <li>
                <b>Advertising Cookies: </b>These cookies are used to deliver
                advertisements that are more relevant to you and your interests.
                They may also be used to limit the number of times you see an
                advertisement and measure the effectiveness of advertising
                campaigns. We may use advertising cookies provided by
                third-party advertising networks such as Google Ads or Facebook
                Ads.
              </li>
            </ul>
            <b>Third-Party Cookies and Tracking Technologies: </b>We may also
            allow third parties to place cookies and similar tracking
            technologies on your device through the Platform for various
            purposes, including analytics, advertising, and social media
            integration. These third parties may collect information about your
            online activities over time and across different websites and apps.
            <b>Managing Cookies and Tracking Technologies: </b>You can control
            and manage cookies and tracking technologies in various ways. Please
            note that blocking or deleting cookies may affect your experience
            using the Platform and may prevent certain features from working
            correctly.
            <ul>
              <li>
                <b>Browser Settings: </b>You can set your web browser to accept
                or reject cookies or to prompt you before accepting a cookie
                from websites you visit. The Help section of your browser should
                provide information on how to manage cookie settings.
              </li>
              <li>
                <b>Mobile Device Settings: </b>Your mobile device may allow you
                to control cookies through its settings. Refer to your device
                manufacturer's instructions for more information.
              </li>
              <li>
                <b>Opting Out: </b>Some third-party ad networks and analytics
                companies offer opt-out tools that allow you to opt out of
                targeted advertising. You can also opt out of certain analytics
                activities by using the opt-out features provided by the
                analytics company.
              </li>
            </ul>
          </p>
        </p>

        <h2 className="section-heading">How We Use Your Information</h2>
        <p className="section-content">
          We may use the collected information for the following purposes:
          <ul>
            <li>Deliver and improve our products and services.</li>
            <li>Manage your account and provide customer support.</li>
            <li>
              Perform research and analysis about your use of our or others'
              products, services, or content.
            </li>
            <li>
              Communicate with you about products or services that may be of
              interest.
            </li>
            <li>
              Develop, display, and track content and advertising tailored to
              your interests.
            </li>
            <li>
              Verify your eligibility and deliver prizes in connection with
              contests and sweepstakes.
            </li>
            <li>
              Enforce our Terms of Use and perform other functions or services
              as described to you at the time of collection.
            </li>
          </ul>
        </p>
        <h2 className="section-heading">Information Sharing</h2>
        <p className="section-content">
          We do not sell or rent your personal information to third parties.
          However, we may share your information with:
          <ul>
            <li>
              <b>Service Providers: </b>We may share your information with
              third-party service providers who assist us in providing and
              improving our Services.
            </li>
            <li>
              <b>Other MABROOK Group Companies: </b>We may share information
              with other MABROOK group companies to provide and improve our
              Services.
            </li>
            <li>
              <b>Legal Compliance: </b>We may disclose your information in
              response to lawful requests by public authorities, to establish or
              exercise our legal rights, to defend against legal claims, to
              protect the rights, property, or safety of our company, users,
              employees, or others, or in connection with a corporate
              transaction.
            </li>
          </ul>
        </p>

        <h2 className="section-heading">Data Security</h2>
        <p className="section-content">
          We implement security measures to protect your personal information
          from unauthorized access, disclosure, alteration, or destruction.
          These measures include encryption techniques, secure servers, access
          controls, and regular security assessments. You have the option to
          hide certain information from your profile, which will only be visible
          to users you "like."
        </p>
        <h2 className="section-heading">Your Rights</h2>
        <p className="section-content">
          You have the right to access, update, or delete your personal
          information. You can also pause your activity, which hides your
          profile from other users while still keeping your data stored with us.
        </p>

        <h2 className="section-heading">Children's Privacy</h2>
        <p className="section-content">
          Our services are not intended for use by children, and we kindly
          request that individuals under the age of 18 refrain from providing
          any personal information to us. Even with parental or guardian
          supervision, individuals under the age of 18 are not permitted to use
          our service. <br /> <br /> While our Services are accessible to
          individuals of all ages, we restrict the use of our service to
          individuals aged 18 and above. We do not knowingly collect, maintain,
          or use personal information from children under the age of 18. If we
          become aware that we have inadvertently collected personal information
          from a child under 18 years of age, we will take steps to delete such
          information from our records promptly.
        </p>

        <h2 className="section-heading">Data Retention and Deletion</h2>
        <p className="section-content">
          We retain your data for analytical purposes, recordkeeping integrity,
          fraud prevention, and legal compliance. After a certain period,
          personally identifiable information associated with your account will
          be anonymized or masked. We also record the exact time you request
          account deletion and permanently delete your personally identifiable
          information after a specified period.
        </p>
        <h2 className="section-heading">
          Privacy Policy Consent and Agreement
        </h2>
        <p className="section-content">
          By using our Services, you consent to the practices described in this
          Privacy Policy and agree to our Terms of Service.
        </p>

        <h2 className="section-heading">
          Data Transfer and International Processing
        </h2>
        <p className="section-content">
          As a global company, MABROOK operates in multiple countries, including
          the European Union. We may transfer personal information to offices
          outside of your country or region, including the United States. By
          providing your personal information, you consent to the transfer and
          processing of your data in accordance with this Privacy Policy. We
          will ensure that any such transfer is carried out in compliance with
          applicable data protection laws and with appropriate safeguards in
          place to protect your privacy.
        </p>

        <h2 className="section-heading">
          Understanding Your Data Protection Rights
        </h2>
        <p className="section-content">
          Our Company aims to ensure that you are fully informed about your data
          protection rights. As a user, you have the following entitlements:
          <ul>
            <li>
              <b>The right to access: </b>You can request copies of your
              personal data from Our Company, and a small fee may be charged for
              this service.
            </li>
            <li>
              <b>The right to rectification: </b>If you believe any information
              held by Our Company is inaccurate or incomplete, you have the
              right to request corrections.
            </li>
            <li>
              <b>The right to erasure: </b> You can request the deletion of your
              personal data from Our Company's records, subject to certain
              conditions.
            </li>
            <li>
              <b>The right to restrict processing: </b> You can request
              limitations on the processing of your personal data by Our
              Company, subject to certain conditions.
            </li>
            <li>
              <b>The right to object to processing: </b> You can object to Our
              Company's processing of your personal data, subject to certain
              conditions.
            </li>
            <li>
              <b>The right to data portability: </b> You can request the
              transfer of your data from Our Company to another organization or
              directly to you, subject to certain conditions.
            </li>
          </ul>
          <p>
            Upon making a request, we will respond within 15 days. To exercise
            any of these rights, please contact us through our contact email on
            our website, or via the contact link provided in our app listing.
          </p>
        </p>

        <h2 className="section-heading">Do Not Track Disclosure</h2>
        <p className="section-content">
          Do Not Track ("DNT") is a privacy preference that users can set in
          their web browsers. While we respect user privacy preferences, we do
          not currently recognize or respond to any DNT signals. As the Internet
          industry continues to define DNT standards and compliance, we are
          committed to providing meaningful choices about the information we
          collect and will update our practices accordingly. For now, please
          refer to our opt-out links and settings options to manage your privacy
          preferences.
        </p>

        <h2 className="section-heading">Updates to This Policy</h2>
        <p className="section-content">
          We may update this Privacy Policy periodically to reflect changes in
          our practices or legal requirements. We will notify you of any
          material changes through our application or other communication
          channels.
        </p>
        <h2 className="section-heading">Contact Us</h2>
        <p className="section-content">
          If you have any questions about this Privacy Policy, please contact us
          by email at: contact@mabrook.co
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
