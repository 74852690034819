import React, { useEffect, useState } from "react";
import Logo from "../assets/images/logos/Mabrook-cut.svg";
import "../styles/Header.css";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/Language";
import { mabrookEmail } from "../utils/constants";
import { useScroll } from "../context/ScrollContext";
import { Link, useNavigate } from "react-router-dom";
import useClickOutsideLanguage from "../utils/useClickOutsideLanguage";
import { LuMenu } from "react-icons/lu";
import { MdArrowDropDown } from "react-icons/md";
import { trackEvent } from "../utils/analytics";

const Header = () => {
  const {
    language,
    changeLanguage,
    languages,
    isLanguageDropdownOpen,
    setIsLanguageDropdownOpen,
    languageDropdownRef,
  } = useLanguage();
  const { t } = useTranslation();
  const { sectionRefs } = useScroll();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(
        currentScrollPos <= 100 ||
          prevScrollPos > currentScrollPos ||
          currentScrollPos < 10
      );
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos, visible]);

  const toggleDropdown = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };

  const handleChangeLanguage = (language) => {
    toggleDropdown(false);
    changeLanguage(language);
  };

  const scrollToSection = (section) => {
    const targetSection = sectionRefs[section]?.current;
    const currentPath = window.location.pathname;
    if (!targetSection || currentPath !== "/") {
      // If target section is on a different page, navigate to the Home page first
      navigate(`/#${section}`);
    } else {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  useClickOutsideLanguage(languageDropdownRef, () => {
    setIsLanguageDropdownOpen(false);
  });

  const SandwichMenu = () => {
    return (
      <div className="sandwich-menu">
        <div className="sandwich-header">
          <Link to="/">
            <img className="sandwich-header-logo" src={Logo} alt="Logo" />
          </Link>
          <LuMenu
            size={30}
            className="sandwich-menu-icon"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          />
        </div>
        <div className="sandwich-navbar-menu">
          <ul>
            <li
              onClick={(e) => {
                e.preventDefault();
                setIsMenuOpen(false);
                trackEvent({ label: "About in Resp. Header" });
                scrollToSection("about");
              }}
            >
              {t(`About`)}
            </li>
            <li
              onClick={(e) => {
                e.preventDefault();
                setIsMenuOpen(false);
                trackEvent({ label: "Features in Resp. Header" });
                scrollToSection("features");
              }}
            >
              {t(`Features`)}
            </li>
            <li
              onClick={(e) => {
                trackEvent({ label: "Contact Us in Resp. Header" });
                window.location.href = `mailto:${mabrookEmail}`;
              }}
            >
              {t(`Contact Us`)}
            </li>
            {/*<li
              onClick={(e) => {
                e.preventDefault();
                setIsMenuOpen(false);
                trackEvent({ label: "Signup in Resp. Header" });
                scrollToSection("signup");
              }}
            >
          {t(`Get Notified`)}
            </li>*/}
            <li
              onClick={(e) => {
                e.preventDefault();
                setIsMenuOpen(false);
                trackEvent({ label: "Signup in Resp. Header" });
                scrollToSection("signup");
              }}
            >
              {t(`Get the app`)}
            </li>
          </ul>
        </div>
        <div className="navbar-language-dropdown" ref={languageDropdownRef}>
          <div className="navbar-selected-language" onClick={toggleDropdown}>
            <p>{language.flag}</p>
            <p>{language.nativeName}</p>
            <MdArrowDropDown size={25} className="navbar-dropdownIcon" />
          </div>
          {isLanguageDropdownOpen && (
            <ul className="sandwich-navbar-dropdown-list">
              {languages.map((lang) => (
                <li
                  style={{ fontFamily: "Lato" }}
                  key={lang.value}
                  onClick={() => handleChangeLanguage(lang)}
                >
                  {lang.nativeName} {lang.flag}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    );
  };

  return (
    <header
      className={`header ${visible ? "visibleHeader" : "invisibleHeader"}`}
    >
      <div>
        <Link to="/">
          <img className="header-logo" src={Logo} alt="Logo" />
        </Link>{" "}
      </div>
      <nav className="nav navRegular">
        <ul>
          <li
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("about");
              trackEvent({ label: "About in Header" });
            }}
          >
            {t(`About`)}
          </li>
          <li
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("features");
              trackEvent({ label: "Features in Header" });
            }}
          >
            {t(`Features`)}
          </li>
          <li
            onClick={(e) => {
              trackEvent({ label: "Contact Us in Header" });
              window.location.href = `mailto:${mabrookEmail}`;
            }}
          >
            {t(`Contact Us`)}
          </li>
          {/*<li
            onClick={(e) => {
              e.preventDefault();
              trackEvent({ label: "Signup in Header" });
              scrollToSection("signup");
            }}
          >
            {t(`Get Notified`)}
          </li>*/}
          <li
            onClick={(e) => {
              e.preventDefault();
              trackEvent({ label: "Signup in Header" });
              scrollToSection("signup");
            }}
          >
            {t(`Get the app`)}
          </li>
        </ul>
      </nav>
      <div className="language-dropdown" ref={languageDropdownRef}>
        <div className="selected-language" onClick={toggleDropdown}>
          {language.nativeName} {"  "}
          {language.flag}
        </div>
        {isLanguageDropdownOpen && (
          <ul className="dropdown-list">
            {languages.map((lang) => (
              <li
                style={{ fontFamily: "Lato" }}
                key={lang.value}
                onClick={() => handleChangeLanguage(lang)}
              >
                {lang.nativeName} {lang.flag}
              </li>
            ))}
          </ul>
        )}
      </div>
      <nav style={{ cursor: "pointer" }} className="navResponsive">
        <LuMenu size={30} onClick={() => setIsMenuOpen(!isMenuOpen)} />
      </nav>
      {isMenuOpen && <SandwichMenu />}
    </header>
  );
};

export default Header;
