import React, { useState } from "react";
import "../styles/Sidebar.css";
import { useTranslation } from "react-i18next";
import { useScroll } from "../context/ScrollContext";
import { useNavigate } from "react-router-dom";

const Sidebar = () => {
  const { t } = useTranslation();
  const { sectionRefs } = useScroll();
  const navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const scrollToSection = (section) => {
    const targetSection = sectionRefs[section]?.current;
    const currentPath = window.location.pathname;
    if (!targetSection || currentPath !== "/") {
      // If target section is on a different page, navigate to the Home page first
      navigate(`/#${section}`);
    } else {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClick = () => {
    scrollToSection("signup");
  };

  return (
    <div className="sidebar-container">
      <div
        className={`signup-button ${isHovered ? "hovered" : ""}`}
        onClick={handleClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {t("Sign Up")}
      </div>
    </div>
  );
};

export default Sidebar;
