import React from "react";
import "../styles/MainSection.css";
import { useTranslation } from "react-i18next";

const MainSection = () => {
  const { t } = useTranslation();

  return (
    <section className="main-section">
      <div className="background-image"></div>
      <div className="content">
        <h2>{t(`Premium Moroccan Matrimony`)}</h2>
        <p className="target-audience">{t(`For Moroccans of the World`)}</p>
      </div>
    </section>
  );
};

export default MainSection;
