import React from "react";
import {
  FaApple,
  FaGooglePlay,
  FaFacebookF,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";
import "../styles/Footer.css";
import logo from "../assets/images/logos/logo-rings.svg";
import { useTranslation } from "react-i18next";
import { mabrookEmail } from "../utils/constants";
import { useScroll } from "../context/ScrollContext";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const { t } = useTranslation();
  const { sectionRefs } = useScroll();
  const navigate = useNavigate();

  const scrollToSection = (section) => {
    const targetSection = sectionRefs[section]?.current;
    const currentPath = window.location.pathname;
    if (!targetSection || currentPath !== "/") {
      // If target section is on a different page, navigate to the Home page first
      navigate(`/#${section}`);
    } else {
      targetSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div>
          <img src={logo} alt="App Logo" className="footer-logo" />
        </div>
        <div className="footer-links">
          <li
            onClick={(e) => {
              e.preventDefault();
              scrollToSection("about");
            }}
            className="footer-link"
          >
            {t(`About Us`)}
          </li>
          <li
            onClick={(e) => {
              window.location.href = `mailto:${mabrookEmail}`;
            }}
            className="footer-link"
          >
            {t(`Contact Us`)}
          </li>
          <li className="footer-link">
            <Link className="footer-inner-link" to="/privacy-policy">
              {t(`Privacy Policy`)}
            </Link>
          </li>
          <li className="footer-link">
            <Link className="footer-inner-link" to="/terms-of-service">
              {t(`Terms of Service`)}
            </Link>
          </li>
        </div>
        <div className="footer-social">
          {/*<a href="#" className="social-icon">
            <FaFacebookF />
          </a>*/}
          <a
            href="https://twitter.com/Mabrook_Morocco"
            className="social-icon"
            target="_blank"
          >
            <FaTwitter />
          </a>
          <a
            href="https://www.instagram.com/mabrook_morocco/"
            className="social-icon"
            target="_blank"
          >
            <FaInstagram />
          </a>
        </div>
      </div>
      <p className="footer-contactEmail">contact@mabrook.co</p>
      <p className="footer-signature">
        © {new Date().getFullYear()} Mabrook. All rights reserved.
      </p>
      <p className="footer-attribution">
        Images used under license from Unsplash, Pexels, Freepik, Shutterstock,
        and Pixabay.
      </p>
      {/*<div className="footer-download">
        <button className="app-store-button">
          <FaApple className="store-icon" />
          Download on the App Store
        </button>
        <button className="play-store-button">
          <FaGooglePlay className="store-icon" />
          Get it on Google Play
        </button>
      </div>*/}
    </footer>
  );
};

export default Footer;
