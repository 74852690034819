import React, { useState, useEffect } from "react";
import Logo from "./assets/images/logos/logo-rings.svg";
import "./App.css";
import "./utils/i18n-config";
import { LanguageProvider } from "./context/Language";
import { ScrollProvider } from "./context/ScrollContext";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import TermsOfService from "./components/TermsOfService";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Home from "./components/Home";
import NotFound from "./components/NotFound";
import ReactGA from "react-ga";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    ReactGA.initialize("G-2B1BX0G1Y0");
  }, []);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, [window.location]);

  return (
    <div className="App">
      {loading ? (
        <div className="app-loading">
          <div className="app-background">
            <img src={Logo} alt="App Logo" className="app-logo" />
          </div>
        </div>
      ) : (
        <ScrollProvider>
          <LanguageProvider>
            <Router>
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route
                  exact
                  path="/privacy-policy"
                  element={<PrivacyPolicy />}
                />
                <Route
                  exact
                  path="/terms-of-service"
                  element={<TermsOfService />}
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Router>
          </LanguageProvider>
        </ScrollProvider>
      )}
    </div>
  );
};

export default App;
