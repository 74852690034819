import React from "react";
import "../styles/Appstores.css";
import playStoreLogoEN from "../assets/images/app-stores-badges/playstore/google-play-badge-en.png";
import playStoreLogoFR from "../assets/images/app-stores-badges/playstore/google-play-badge-fr.png";
import playStoreLogoES from "../assets/images/app-stores-badges/playstore/google-play-badge-es.png";
import playStoreLogoAR from "../assets/images/app-stores-badges/playstore/google-play-badge-ar.png";
import playStoreLogoIT from "../assets/images/app-stores-badges/playstore/google-play-badge-it.png";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/Language";
import appstoreLogo from "../assets/images/appstorelogo.png";

const Appstores = () => {
  const { language } = useLanguage();
  const { t } = useTranslation();

  const renderPlayStoreBadge = () => {
    const imageSource =
      language.value === "en"
        ? playStoreLogoEN
        : language.value === "fr"
        ? playStoreLogoFR
        : language.value === "es"
        ? playStoreLogoES
        : language.value === "ar"
        ? playStoreLogoAR
        : playStoreLogoIT;
    return <img src={imageSource} alt="Play Store" className="store-logo" />;
  };

  return (
    <div className="appstores">
      {/*<div className="store-link">
        <a
          href="https://play.google.com/store/apps/details?id=com.mabrook.mabrookmorocco"
          target="_blank"
          rel="noopener noreferrer"
        >
          {renderPlayStoreBadge()}
        </a>
      </div>*/}
      <p
        className={
          language.value === "ar"
            ? "coming-soon-on-apple-arabic"
            : "coming-soon-on-apple"
        }
      >
        <p className="coming-soon-italic-text">
          {t(`Coming soon`)} ⌛️ 👀
          {"   "}
        </p>{" "}
      </p>
    </div>
  );
};

export default Appstores;
