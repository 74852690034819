import React, { useRef } from "react";
import "../styles/About.css";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/Language";
import { useScroll } from "../context/ScrollContext";

const About = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const sectionRef = useRef(null);
  const { sectionRefs } = useScroll();
  sectionRefs.about = sectionRef;

  return (
    <section ref={sectionRef} id="about-section" className="about-section">
      <h2>{t(`About Mabrook`)}</h2>
      <p>
        {language.value === "ar"
          ? t(`aboutDescription`)
          : "Mabrook" + t(`aboutDescription`)}
      </p>
    </section>
  );
};

export default About;
