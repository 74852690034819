import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "../locales/en.json";
import fr from "../locales/fr.json";
import es from "../locales/es.json";
import ar from "../locales/ar.json";
import it from "../locales/it.json";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    fr: { translation: fr },
    ar: { translation: ar },
    es: { translation: es },
    it: { translation: it },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false, // set to false cause we're not using Suspense
    wait: true, // set to true to wait for loaded translations in render
  },
  pluralSeparator: "_",
  contextSeparator: "_",
});

export default i18n;
