import React, { createContext, useContext, useRef, useState } from "react";

const ScrollContext = createContext();

export const ScrollProvider = ({ children }) => {
  const [sectionRefs, setSectionRefs] = useState({
    about: useRef(null),
    features: useRef(null),
    signup: useRef(null),
  });

  return (
    <ScrollContext.Provider value={{ sectionRefs, setSectionRefs }}>
      {children}
    </ScrollContext.Provider>
  );
};

export const useScroll = () => useContext(ScrollContext);
