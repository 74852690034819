import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../styles/NotFound.css";
import { useTranslation } from "react-i18next";
import Sidebar from "./Sidebar";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="not-found-container">
      <Header />
      <Sidebar />
      <div className="not-found-wrapper">
        <h2 className="not-found-heading">{t(`notFoundHeader`)}</h2>
        <p className="not-found-message">{t(`notFoundDesc`)}</p>
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
