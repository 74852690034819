import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Features from "./Features";
import MainSection from "./MainSection";
import About from "./About";
import MembershipFeaturesTable from "./MembershipFeaturesTable";
import SignUp from "./SignUp";
import { useScroll } from "../context/ScrollContext";
import Sidebar from "./Sidebar";
import Countdown from "./Countdown";
import Download from "./Download";

const Home = () => {
  const { sectionRefs } = useScroll();
  const [showSidebar, setShowSidebar] = useState(true);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const sectionId = hash.substring(1);
      const targetSection = sectionRefs[sectionId]?.current;
      if (targetSection) {
        targetSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [window.location.hash, sectionRefs]);

  useEffect(() => {
    const handleScroll = () => {
      const signUpSection = sectionRefs["signup"]?.current;
      if (signUpSection) {
        const signUpSectionPosition = signUpSection.getBoundingClientRect();
        // Check if the SignUp section is in the viewport
        if (
          signUpSectionPosition.top >= 0 &&
          signUpSectionPosition.bottom <= window.innerHeight
        ) {
          setShowSidebar(false);
        } else {
          setShowSidebar(true);
        }
      }
    };
    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [window.location, sectionRefs]);

  return (
    <>
      <Header />
      {showSidebar && <Sidebar />}
      <MainSection />
      <About />
      <Features />
      <MembershipFeaturesTable />
      {/*<SignUp />*/}
      {/*<Countdown />*/}
      <Download />
      <Footer />
    </>
  );
};

export default Home;
