import React, { createContext, useContext, useState, useRef } from "react";
import i18n from "../utils/i18n-config";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState({
    nativeName: "English",
    flag: "🇺🇸 ",
    value: "en",
  });
  const [languages, setLanguages] = useState([
    { nativeName: "English", flag: "🇺🇸 ", value: "en" },
    { nativeName: "Français", flag: "🇫🇷 ", value: "fr" },
    { nativeName: "العربية", flag: "🇲🇦 ", value: "ar" },
    { nativeName: "Español", flag: "🇪🇸 ", value: "es" },
    { nativeName: "Italiano", flag: "🇮🇹 ", value: "it" },
  ]);
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);
  const languageDropdownRef = useRef(null);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng.value);
    setLanguage(lng);
  };

  const toggleLanguageDropdown = () => {
    setIsLanguageDropdownOpen(!isLanguageDropdownOpen);
  };

  return (
    <LanguageContext.Provider
      value={{
        language,
        changeLanguage,
        languages,
        isLanguageDropdownOpen,
        setIsLanguageDropdownOpen,
        toggleLanguageDropdown,
        languageDropdownRef,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useLanguage = () => useContext(LanguageContext);
