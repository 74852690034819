import React, { useRef } from "react";
import "../styles/MembershipFeaturesTable.css";
import { useTranslation } from "react-i18next";
import { useScroll } from "../context/ScrollContext";

const MembershipFeaturesTable = () => {
  const { t } = useTranslation();
  const sectionRef = useRef(null);
  const { sectionRefs } = useScroll();

  sectionRefs.features = sectionRef;

  return (
    <div
      ref={sectionRef}
      id="features-section"
      className="membership-features-container"
    >
      <table className="membership-features-table">
        <thead>
          <tr>
            <th>{t(`Feature`)}</th>
            <th>{t(`Free`)}</th>
            <th>{t(`Standard`)}</th>
            <th>{t(`Premium`)}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t(`Create a profile`)}</td>
            <td className="membershipFeaturesTableFeature">✔</td>
            <td className="membershipFeaturesTableFeature">✔</td>
            <td className="membershipFeaturesTableFeature">✔</td>
          </tr>
          <tr>
            <td>{t(`Messaging`)}</td>
            <td className="membershipFeaturesTableFeature">✔</td>
            <td className="membershipFeaturesTableFeature">✔</td>
            <td className="membershipFeaturesTableFeature">✔</td>
          </tr>
          <tr>
            <td>{t(`Basic Filters`)}</td>
            <td className="membershipFeaturesTableFeature">✔</td>
            <td className="membershipFeaturesTableFeature">✔</td>
            <td className="membershipFeaturesTableFeature">✔</td>
          </tr>
          <tr>
            <td>{t(`Advanced Filters`)}</td>
            <td></td>
            <td className="membershipFeaturesTableFeature">✔</td>
            <td className="membershipFeaturesTableFeature">✔</td>
          </tr>
          <tr>
            <td>{t(`Unlimited likes`)}</td>
            <td></td>
            <td className="membershipFeaturesTableFeature">✔</td>
            <td className="membershipFeaturesTableFeature">✔</td>
          </tr>
          <tr>
            <td>{t(`Message users who liked you`)}</td>
            <td></td>
            <td className="membershipFeaturesTableFeature">✔</td>
            <td className="membershipFeaturesTableFeature">✔</td>
          </tr>
          <tr>
            <td>{t(`View mutual likes`)}</td>
            <td></td>
            <td className="membershipFeaturesTableFeature">✔</td>
            <td className="membershipFeaturesTableFeature">✔</td>
          </tr>
          <tr>
            <td>{t(`See others' dynamic cards`)}</td>
            <td></td>
            <td className="membershipFeaturesTableFeature">✔</td>
            <td className="membershipFeaturesTableFeature">✔</td>
          </tr>
          <tr>
            <td className="boldedFeature">{t(`Matching algorithm`)}</td>
            <td></td>
            <td></td>
            <td className="membershipFeaturesTableFeature">✔</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MembershipFeaturesTable;
