import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import "../styles/TermsOfService.css";
import Sidebar from "./Sidebar";

const TermsOfService = () => {
  return (
    <>
      <Header />
      <Sidebar />
      <div className="terms-of-service-container">
        <h1 className="terms-of-service-heading">Terms of Service</h1>
        <p className="terms-of-service-description">
          These Terms of Service ("Terms") govern your access to and use of the
          Mabrook mobile application ("Mabrook" or the "App"), including any
          content, features, and services offered by Mabrook LLC ("Mabrook LLC",
          "we", "us", or "our") through the App or the website www.mabrook.co
          ("Website"). By accessing or using Mabrook, you agree to these Terms.
          If you do not agree to these Terms, please do not access or use
          Mabrook.
        </p>

        <h2 className="section-heading">1. Use of Service</h2>
        <p className="section-content">
          <ul>
            <li>
              The Service is intended for Moroccan single individuals aged 18
              and above who seek a marriage partner. By accessing or using
              Mabrook, you represent and warrant that you are at least 18 years
              old and have the legal capacity to enter into these Terms.
            </li>
            <li>
              If you become a Member, you will be able to access the Services
              associated with the Company product(s) for which you hold a
              Membership. If you meet certain requirements, the Company may in
              its discretion make your profile visible to Users of other
              Websites and Apps operated by the Company or group companies for
              which you do not have a Membership.
            </li>
            <li>
              You are reminded to abide by all applicable laws. You also
              undertake not to use our Services for unlawful, harmful,
              threatening, abusive, harassing, tortious, defamatory, vulgar,
              obscene, libelous, hateful, or racially or ethnically offensive
              purposes (or for purposes which are otherwise objectionable).
            </li>
            <li>
              If you violate these Terms & Conditions, we may terminate your
              access to our Services.
            </li>
            <li>
              By continuing to use Mabrook after any modifications to these
              Terms, you agree to be bound by the revised Terms. It is your
              responsibility to review these Terms periodically for changes.
            </li>
          </ul>
        </p>
        <h2 className="section-heading">2. Account Registration</h2>
        <p className="section-content">
          To use certain features of Mabrook, you may be required to create an
          account. You agree to provide accurate, current, and complete
          information during the registration process and to update such
          information to keep it accurate, current, and complete. You are
          responsible for safeguarding your account credentials and for any
          activities or actions taken under your account.
        </p>
        <p className="section-content">
          You also agree to continually maintain and revise your information to
          ensure its accuracy, currency, and completeness. We have the right to
          trust that your registration data is accurate, current, and complete.
          If we find that your Registration Data is false, inaccurate, outdated,
          or incomplete in any way, we reserve the right to terminate this
          Agreement and your access to the App or the website.
        </p>
        <h2 className="section-heading">3. Termination</h2>
        <p className="section-content">
          The duration and conclusion of this Agreement are as follows: It will
          remain valid and enforceable while you utilize the Website, App and/or
          hold membership status. You reserve the right to end your membership
          at any time, for any reason. Similarly, either party may terminate
          your membership by suspending or deleting your profile, at any time,
          for any reason, with or without explanation.
        </p>
        <p className="section-content">
          If (a) you terminate your subscription or membership, or (b) we, at
          our sole discretion, determine that you have breached this Agreement,
          you are not entitled to, nor will we be liable to provide you with,
          any refund for any unused portion of payments we have received from
          you. Additionally, we may continue to prohibit your future use of the
          Service. Even after membership termination, this Agreement will
          persist.
        </p>
        <h2 className="section-heading">4. Prohibited Conduct</h2>
        <p className="section-content">
          You agree to utilize the Website and/or App in accordance with all
          applicable laws and regulations. Personal contact information, social
          media handles, phone numbers, street addresses, last names, URLs, or
          email addresses will not be included in your profile, except where
          specifically requested in the Confidential Personal Information
          section of the Service. You will refrain from engaging in advertising
          or solicitation of products or services to other members through the
          Service. The Website and/or App will not be used if you are married or
          to solicit or offer unofficial forms of marriage, such as orfi or
          motaa marriage, or mesyar marriage. Additionally, the Service will not
          be used to request, offer, promote, or discuss any fetishes, including
          but not limited to BDSM. Chain letters or junk email will not be
          transmitted to other members, and you are solely accountable for your
          interactions with them. While we reserve the right to monitor and
          mediate disputes between you and other members, we are not obligated
          to do so.
        </p>
        <p className="section-content">
          You bear sole responsibility for the content or information you
          publish or display (referred to as "posting") on the Website and/or
          App transmit to other members. Posting defamatory, inaccurate,
          abusive, obscene, profane, offensive, sexually oriented, threatening,
          harassing, racially offensive, or illegal material, or material that
          infringes or violates another party's rights (including intellectual
          property rights, and rights of privacy and publicity) is prohibited.
          Your profile should not contain offensive anatomical or sexual
          references, sexually suggestive language, or photos containing nudity
          or personal information. We retain the right, without obligation, to
          reject any profile or photo that violates the prohibitions outlined in
          this section, and in our sole discretion, you will not be entitled to
          a refund of any payments made to us.
        </p>
        <p className="section-content">
          You agree not to:
          <ul>
            <li>Violate any applicable laws or regulations</li>
            <li>Impersonate any person or entity</li>
            <li>Interfere with or disrupt the Service or servers</li>
            <li>
              Engage in any fraudulent activity, including but not limited to
              creating fake profiles, misleading other users, or attempting to
              solicit money or personal information from other users.
            </li>
            <li>Harass, intimidate, or discriminate against others</li>
          </ul>
        </p>
        <h2 className="section-heading">5. User Content</h2>
        <p className="section-content">
          You retain ownership of any content you create or upload to Mabrook
          ("User Content"). By creating or uploading User Content, you grant
          Mabrook LLC a worldwide, non-exclusive, royalty-free, sublicensable,
          and transferable license to use, reproduce, distribute, prepare
          derivative works of, display, and perform the User Content in
          connection with Mabrook and Mabrook LLC's business, including for
          promotional and marketing purposes. You represent and warrant that you
          have all necessary rights to grant the foregoing license.
        </p>
        <h2 className="section-heading">6. Membership Plans</h2>
        <p className="section-content">
          Mabrook offers three membership plans: free, standard, and premium.
          Each plan may have different features and limitations, which are
          detailed on the app and website's home page. By subscribing to a
          membership plan, you agree to pay the applicable fees and abide by the
          terms and conditions of that plan. You may manage your membership
          subscription settings, including opting in or out of automatic
          subscription renewal, at any time through the Membership tab in the
          app. Any fees paid for membership plans are non-refundable, except as
          required by applicable law.
        </p>
        <h2 className="section-heading">7. Intellectual Property</h2>
        <p className="section-content">
          Mabrook and its content, features, and services are owned by Mabrook
          LLC and are protected by copyright, trademark, and other intellectual
          property laws. All trademarks, service marks, logos, and trade names
          displayed on Mabrook are the property of Mabrook LLC or their
          respective owners. You may not use, copy, reproduce, modify,
          distribute, transmit, or create derivative works of any content on
          Mabrook without the prior written consent of Mabrook LLC.
        </p>
        <p className="section-content">
          You affirm and assure us that the information and photographs
          displayed in your profile are provided by you, and that you are the
          sole creator of your profile and the exclusive owner of the
          photographs therein. You transfer to us, with complete title
          guarantee, all copyright associated with your profile and photographs
          posted by you at any time in connection with your use of the Service.
          You unconditionally waive any moral rights to be acknowledged as the
          author of your profile or the owner of your photographs, and any
          similar rights in any jurisdiction worldwide.
        </p>
        <p className="section-content">
          Furthermore, other members may post copyrighted information that is
          protected by copyright, whether or not it is explicitly identified as
          such. Except for information in the public domain or for which you
          have obtained express written permission, you agree not to copy,
          modify, publish, transmit, distribute, perform, display, or sell any
          such proprietary information.
        </p>
        <p className="section-content">
          By posting information, photographs, or content on the App or Website,
          you automatically grant, and you confirm and guarantee that you have
          the right to grant, to us and other members, a non-exclusive,
          royalty-free, fully-paid, worldwide license that is irrevocable and
          perpetual, to use, copy, perform, display, promote, publish, and
          distribute such information, content, and photographs freely, and to
          create derivative works from them, or incorporate them into other
          works, and to authorize and grant sublicenses of the foregoing.
        </p>
        <h2 className="section-heading">
          8. Promotions, Contests, and Sweepstakes
        </h2>
        <p className="section-content">
          From time to time, Mabrook may offer promotions, contests, or
          sweepstakes. Participation in such promotions, contests, or
          sweepstakes may be subject to additional terms and conditions, which
          will be made available at the time of participation. Participation in
          promotions, contests, or sweepstakes is voluntary, and additional
          terms and conditions may apply. By participating, you agree to abide
          by such terms and conditions.
        </p>
        <h2 className="section-heading">9. Infringement Notices</h2>
        <p className="section-content">
          If you believe that any content on Mabrook infringes your copyright or
          other intellectual property rights, please contact us at
          contact@mabrook.co.
        </p>
        <h2 className="section-heading">10. Dispute Resolution</h2>
        <p className="section-content">
          Any dispute arising out of or relating to these Terms or your use of
          Mabrook shall be resolved exclusively through arbitration in Estonia
          in accordance with the rules of the Estonian Chamber of Commerce.
        </p>

        <h2 className="section-heading">11. Disclaimer</h2>
        <p className="section-content">
          The Service is provided on an "as-is" and "as-available" basis.{" "}
          <b>Mabrook</b> makes no representations or warranties of any kind,
          express or implied, regarding the Service or its availability,
          accuracy, reliability, suitability, or fitness for a particular
          purpose. Mabrook LLC does not endorse, guarantee, or verify the
          accuracy, completeness, or reliability of user-generated content. You
          acknowledge that any reliance on such content is at your own risk.
        </p>
        <p className="section-content">
          While each member must consent to our terms and conditions, we cannot
          assure that each member meets the minimum age requirement, nor do we
          hold responsibility or liability for any content, communication, or
          other use or access of the Service by individuals under the age of 18,
          which violates this Agreement. Additionally, there is a possibility
          that other members or users, including unauthorized users or
          "hackers," may post or transmit offensive or obscene materials on the
          Service, and you may unintentionally encounter such content. It is
          also conceivable that others may obtain your personal information
          through your use of the Service and misuse it to harass or harm you.
          We do not bear responsibility for the utilization of any personal
          information you disclose on the Service. Please exercise caution in
          the type of information you share on the Service or release to others.
          We absolve ourselves of all liability, regardless of the form of
          action, for the actions or inactions of other members or users,
          including unauthorized users, whether such actions or inactions occur
          during the use of the Service or otherwise.
        </p>
        <h2 className="section-heading">12. Limitation of Liability</h2>
        <p className="section-content">
          <b>Mabrook</b> shall not be liable for any indirect, incidental,
          special, consequential, or punitive damages, including without
          limitation, loss of profits, data, or goodwill, arising out of or in
          connection with your use of the Service or these Terms.Under no
          circumstances shall Mabrook LLC be held liable to you for any
          incidental, consequential, or indirect damages (including, but not
          limited to, damages for loss of data, loss of programs, cost of
          procuring substitute services, or service interruptions) arising from
          the use of or inability to use the Service, even if we or our agents
          or representatives are aware of or have been advised of the
          possibility of such damages, or to any person other than you. Your
          sole recourse in the event of any deficiency, defect, failure, error,
          or interruption shall be to request that we rectify the issue or, if
          we fail to do so, to discontinue the use of the Service or any
          relevant goods or services, at your discretion.
        </p>
        <p className="section-content">
          We do not conduct background checks or screen the members registering
          for the Service in any manner. Consequently, we shall not be held
          liable for any damages, whether direct, indirect, incidental, and/or
          consequential, resulting from the use of this Service. This includes
          damages arising from communication and/or meetings with other members
          of the Service, or individuals introduced to you via the Service. Such
          damages may include, but are not limited to, physical harm, bodily
          injury, and emotional distress and discomfort.
        </p>
        <h2 className="section-heading">13. Governing Law</h2>
        <p className="section-content">
          These Terms shall be governed by and construed in accordance with the
          laws of Estonia, without regard to its conflict of law principles. By
          using Mabrook, users from all countries, including Morocco, agree to
          submit to the jurisdiction of Estonian courts for any disputes arising
          from the use of the app.
        </p>
        <h2 className="section-heading">14. International Compliance</h2>
        <p className="section-content">
          Users from various countries, including Morocco, acknowledge that the
          services provided by Mabrook are subject to compliance with applicable
          international laws and regulations, including but not limited to data
          protection and privacy laws.
        </p>
        <h2 className="section-heading">15. Indemnity</h2>
        <p className="section-content">
          To the maximum extent permitted by applicable law, you consent to
          indemnify, defend, and hold harmless Mabrook and our respective past,
          present, and future employees, officers, directors, contractors,
          consultants, equity holders, suppliers, vendors, service providers,
          parent companies, subsidiaries, affiliates, agents, representatives,
          predecessors, successors, and assigns (collectively referred to as the
          Mabrook Parties”) against any and all actual or alleged claims,
          damages, awards, judgments, losses, liabilities, obligations,
          penalties, interest, fees, expenses (including but not limited to
          attorneys’ fees and expenses), and costs (including but not limited to
          court costs, settlement costs, and costs related to seeking
          indemnification and insurance), of any kind and nature whatsoever,
          whether known or unknown, anticipated or unanticipated, matured or
          unmatured, or suspected or unsuspected, in law or equity, whether
          arising in tort, contract, or otherwise (referred to collectively as
          “Claims”). These Claims include, but are not limited to, damages to
          property or personal injury, and are caused by, arise from, or are
          connected to (a) your use or misuse of the App, Content, or Website,
          (b) any Feedback you provide, (c) your breach of these Terms, (d) your
          infringement of another's rights, (e) any third party’s use or misuse
          of the Site or Services provided to you, and (f) any User Content you
          generate, post, share, or store on or through the Website, App or our
          pages or feeds on third-party social media platforms. You undertake to
          promptly inform Mabrook of any third-party Claims and to collaborate
          with the Mabrook Parties in defending such Claims. Furthermore, you
          agree that the Mabrook Parties shall have the authority to manage the
          defense or settlement of any third-party Claims. This indemnification
          is supplementary to, and not a substitute for, any other indemnities
          stipulated in a written agreement between you and Mabrook.
        </p>
        <h2 className="section-heading">16. Language</h2>
        <p className="section-content">
          While these Terms are provided in English, translations may be
          available for the convenience of users. In case of any discrepancies
          between the English version and translated versions, the English
          version shall prevail.
        </p>
        <h2 className="section-heading">16. Changes to Terms</h2>
        <p className="section-content">
          We reserve the right to modify or update these Terms at any time. Any
          changes to these Terms will be effective immediately upon posting.
          Your continued use of Mabrook after the posting of revised Terms
          constitutes your acceptance of such changes. We may notify you of
          changes to these Terms by posting the revised Terms on Mabrook or by
          sending an email to the email address associated with your account. We
          may also provide in-app notifications or update the Terms without
          explicit notification.
        </p>
        <h2 className="section-heading">17. Contact</h2>
        <p className="section-content">
          If you have any questions or concerns regarding these Terms or
          Mabrook, you may contact us at contact@mabrook.co or directly through
          the App.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsOfService;
