import ReactGA from "react-ga";

export const trackEvent = ({
  category = "User",
  action = "Cliked On",
  label,
}) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};
