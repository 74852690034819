import React from "react";
import "../styles/Features.css";
import { features } from "../utils/constants";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../context/Language";

const FeatureItem = ({ title, description, images, alignment, language }) => {
  const titlePath = title[language] || title["en"];
  const descriptionPath = description[language] || description["en"];

  return (
    <div className={`feature-item`}>
      {alignment === "left" && (
        <div className="phone-frame-container">
          {images.map((image, index) => (
            <div
              className={`${
                index === 1 ? "phone-frame-tilted" : "phone-frame"
              }`}
              key={index}
            >
              <img
                src={image[language] || image["en"]}
                alt={titlePath}
                className="phone-image"
              />
            </div>
          ))}
        </div>
      )}
      <div className="feature-text">
        <h3>{titlePath}</h3>
        <p>{descriptionPath}</p>
      </div>
      {alignment === "right" && (
        <div className="phone-frame-container">
          {images.map((image, index) => (
            <div
              className={`${
                index === 1 ? "phone-frame-tilted" : "phone-frame"
              }`}
              key={index}
            >
              <img
                src={image[language] || image["en"]}
                alt={titlePath}
                className="phone-image"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const FeatureItemResponsive = ({ title, description, images, language }) => {
  const titlePath = title[language] || title["en"];
  const descriptionPath = description[language] || description["en"];
  const hasTwoImages = images.length > 1;

  return (
    <div className={`feature-item-responsive`}>
      <div className="feature-text-responsive">
        <h3>{titlePath}</h3>
        <p>{descriptionPath}</p>
      </div>
      <div
        className={`${
          hasTwoImages
            ? "phone-frame-container-responsive-two-images"
            : "phone-frame-container-responsive"
        }`}
      >
        {images.map((image, index) => (
          <div
            className={`${
              index === 1
                ? "phone-frame-tilted-responsive"
                : "phone-frame-responsive"
            }`}
            key={index}
          >
            <img
              src={image[language] || image["en"]}
              alt={titlePath}
              className="phone-image-responsive"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

const Features = () => {
  const { language } = useLanguage();
  const { t } = useTranslation();

  return (
    <>
      <section className="features-section">
        {features.map((feature, index) => (
          <FeatureItem
            key={index}
            title={feature.title}
            description={feature.description}
            images={feature.images}
            alignment={index % 2 === 0 ? "left" : "right"}
            language={language.value}
          />
        ))}
        <p className="andMuchMore">{t(`And much more!`)}</p>
      </section>
      <section className="features-section-responsive">
        {features.map((feature, index) => (
          <FeatureItemResponsive
            key={index}
            title={feature.title}
            description={feature.description}
            images={feature.images}
            language={language.value}
          />
        ))}
        <p className="andMuchMore">{t(`And much more!`)}</p>
      </section>
    </>
  );
};

export default Features;
