import React, { useEffect, useRef } from "react";
import "../styles/Download.css";
import { useTranslation } from "react-i18next";
import { useScroll } from "../context/ScrollContext";
import QRCode from "qrcode";
import Appstores from "./Appstores";

const apkLink = "/apk/mabrookAPK.apk"; // Path to the APK in the public directory

const Download = () => {
  const { t } = useTranslation();
  const { sectionRefs } = useScroll();
  const sectionRef = useRef(null);
  const qrCodeRef = useRef(null);

  sectionRefs.signup = sectionRef;

  useEffect(() => {
    const generateQRCode = async () => {
      try {
        const url = `${window.location.origin}${apkLink}`;
        const qrCodeDataURL = await QRCode.toDataURL(url);
        if (qrCodeRef.current) {
          qrCodeRef.current.src = qrCodeDataURL;
        }
      } catch (err) {
        console.error(err);
      }
    };

    generateQRCode();
  }, [apkLink]);

  const downloadAPK = () => {
    const link = document.createElement("a");
    link.href = apkLink;
    link.download = "mabrookAPK.apk";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div ref={sectionRef} className="countdown-timer-wrapper">
      <div className="download-container">
        {/*<div className="download-button-wrapper">
          <h1>Download for Android</h1>
          <button className="download-button" onClick={downloadAPK}>
            Download APK
          </button>
        </div>
        <div className="qr-code">
          <img ref={qrCodeRef} alt="QR Code" />
        </div>*/}
        <Appstores />
      </div>
    </div>
  );
};

export default Download;
